import { Divider, ListItem, ListItemText } from '@material-ui/core';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import ListItemButton from '@mui/material/ListItemButton';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '@recoils/atoms';
import { UserGroup } from '@models/user';
import {
  ADMIN_MENU_LIST,
  PARTNER_MENU_LIST_WITH_MANUAL_ORDER,
  PARTNER_MENU_LIST,
} from '@constants/menu';
import useAuth from '@hooks/useAuth';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const LeftSideBar = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const { isAuthenticated } = useAuth();

  const menuList = useMemo(() => {
    if (!user || !isAuthenticated) return [];
    const { group } = user;
    if (
      group === UserGroup.ADMINISTRATOR ||
      group === UserGroup.PRODUCT_MANAGER ||
      group === UserGroup.MANAGER
    ) {
      return ADMIN_MENU_LIST;
    }
    if (group === UserGroup.PARTNER) {
      if (user.manualOrderEnabled) {
        return PARTNER_MENU_LIST_WITH_MANUAL_ORDER;
      } else {
        return PARTNER_MENU_LIST;
      }
    } else {
      return [];
    }
  }, [user, isAuthenticated]);

  const handleClick = (menuName: string, path?: string) => {
    const menu = menuList.find((item) => item.menu === menuName)!!;
    setMenuState((prev) => ({
      ...prev,
      [menuName]: !prev[menuName],
    }));
    if (path) {
      navigate(path);
    }
  };
  const [menuState, setMenuState] = useState<Record<string, boolean>>({});
  useEffect(() => {
    const newState: Record<string, boolean> = {};
    menuList.forEach((menu) => {
      newState[menu.menu] = true;
      setMenuState(newState);
    });
  }, [menuList]);

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: { width: WIDTH },
      }}
      sx={{
        width: WIDTH,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: WIDTH,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      {menuList.map((menu) => (
        <React.Fragment key={'menu' + menu.menu}>
          {menu.menu === 'Home' ? (
            <ListItem
              style={{
                backgroundColor: '#f5f5f5',
              }}
            >
              <ListItemButton onClick={() => handleClick(menu.menu, '/home')}>
                <ListItemText
                  primaryTypographyProps={{
                    style: { fontWeight: 'bold', fontSize: '1.2rem' },
                  }}
                  primary={menu.menu}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <>
              <ListItem
                style={{
                  backgroundColor: '#f5f5f5',
                }}
              >
                <ListItemButton onClick={() => handleClick(menu.menu)}>
                  <ListItemText
                    primaryTypographyProps={{
                      style: { fontWeight: 'bold', fontSize: '1.2rem' },
                    }}
                    primary={menu.menu}
                  />
                  {menuState[menu.menu] ? <ArrowDropUp /> : <ArrowDropDown />}
                </ListItemButton>
              </ListItem>
              {menuState[menu.menu] &&
                menu.subMenu.map((subMenu) => (
                  <ListItem key={subMenu.name}>
                    <ListItemButton
                      onClick={() => {
                        handleClick(subMenu.name, subMenu.path);
                      }}
                    >
                      <ListItemText primary={subMenu.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </>
          )}
        </React.Fragment>
      ))}
      <Divider />
    </Drawer>
  );
};

const WIDTH = 240;

export default LeftSideBar;
