import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getUploadHistoryDetailsCount } from '@apis/productBulk/upload';

export const useUploadDetailsCount = (uploadId: number) => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['productUploadDetailsCount', uploadId],
    queryFn: () => {
      return getUploadHistoryDetailsCount(uploadId);
    },
  };
  return useQuery(queryOptions);
};
