import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import {
  GetUploadHistoriesResponse,
  getUploadHistories,
} from '@apis/productBulk/upload';

export const useUploadHistories = ({ page }: { page: number }) => {
  const queryOptions: UseQueryOptions<
    PageResponse<GetUploadHistoriesResponse>,
    Error
  > = {
    queryKey: ['productUploadHistories', page],
    queryFn: () => {
      return getUploadHistories({
        pageNo: page,
        pageSize: 50,
      });
    },
  };
  return useQuery(queryOptions);
};
