import API from './API';
import { PageParams, PageResponse } from '@models/api';
import { EsimEventType, EsimStatus, EsimType } from '@models/stock';
import { EsimStock } from '@models/esim';

export enum EsimInventoryParameterType {
  REDEMPTION_CODE = 'REDEMPTION_CODE',
  LPA_URL = 'LPA_URL',
  ICCID = 'ICCID',
  MSISDN = 'MSISDN',
}

export type GetEsimInventoriesParams = {
  stockId?: number;
  esimType?: EsimType;
  esimStatus?: EsimStatus;
  expireAtFrom?: string;
  expireAtTo?: string;
  customerName?: string;
} & ( // both params should be present
  | {
      parameterType: EsimInventoryParameterType;
      parameterValue: string;
    }
  | never
);

export type EsimInventoryResponse = {
  esimInventoryId: number;
  redemptionCode?: string;
  lpaUrl?: string;
  iccid?: string;
  imsi?: string;
  msisdn?: string;
  pin?: string;
  puk?: string;
  serialNo: string;
  esimType: EsimType;
  esimStatus: EsimStatus;
  expireAt: number;
  stock: EsimStock;
};

export type EsimInventoryEventResponse = {
  esimInventoryEventId: number;
  eventType: EsimEventType;
  owner: string;
  description: string;
  orderId?: number;
  createdAt: number;
};

export const getEsimInventoryCount = async (
  params: GetEsimInventoriesParams
) => {
  const { data } = await API.get<PageResponse<number>>('/v1/esims/count', {
    params,
  });
  return data.data[0];
};

export const getEsimInventories = async (
  params: GetEsimInventoriesParams & PageParams
) => {
  const { data } = await API.get<PageResponse<EsimInventoryResponse>>(
    '/v1/esims',
    { params }
  );
  return data;
};

export const getEsimInventory = async (esimInventoryId: number) => {
  const { data } = await API.get<PageResponse<EsimInventoryResponse>>(
    `/v1/esims/${esimInventoryId}`
  );
  return data;
};

export const getEsimInventoryEvents = async (esimInventoryId: number) => {
  const { data } = await API.get<PageResponse<EsimInventoryEventResponse>>(
    `/v1/esims/${esimInventoryId}/events`
  );

  return data;
};

export const addEsimInventoryMemo = async (
  esimInventoryId: number,
  memo: string
): Promise<void> =>
  API.post(`/v1/esims/${esimInventoryId}/memo`, {
    memo: memo,
  });

export const deleteEsimInventoryMemo = async (
  esimInventoryId: number,
  esimInventoryEventId: number
): Promise<void> =>
  API.delete(`/v1/esims/${esimInventoryId}/memo/${esimInventoryEventId}`);

export const exportEsimInventoriesExcel = async (
  params: GetEsimInventoriesParams
) => {
  const { data } = await API.get('/v1/esims/export-excel', {
    params,
    responseType: 'blob',
  });
  return data;
};

export type EsimInventoryUpsertRequest = {
  esimInventoryId: number;
  esimStatus: EsimStatus;
  expireAt: number;
};

export const editEsimInventory = async (
  data: EsimInventoryUpsertRequest
): Promise<void> => API.put(`/v1/esims/${data.esimInventoryId}`, data);
