import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PageResponse } from '@models/api';
import {
  GetUploadHistoryDetailsParams,
  GetUploadHistoryDetailsResponse,
  getUploadHistoryDetails,
} from '@apis/productBulk/upload';

export const useUploadDetails = ({
  page,
  pageSize,
  uploadId,
  params,
}: {
  page: number;
  pageSize: number;
  uploadId: number;
  params: GetUploadHistoryDetailsParams;
}) => {
  const queryOptions: UseQueryOptions<
    PageResponse<GetUploadHistoryDetailsResponse>,
    Error
  > = {
    queryKey: ['productUploadHistoryDetails', uploadId, !!params.onlyFailed],
    queryFn: () => {
      if (!params.onlyFailed) delete params.onlyFailed;
      return getUploadHistoryDetails(uploadId, {
        pageNo: page,
        pageSize: pageSize,
        ...params,
      });
    },
  };
  return useQuery(queryOptions);
};
