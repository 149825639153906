import {
  Box,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useState } from 'react';
import IconButton from '@components/common/IconButton';
import { DeleteOutline } from '@mui/icons-material';
import Button from '@components/common/Button';
import DragDropFile from '@components/common/DragDropFile';
import { useNavigate } from 'react-router-dom';
import { useUploadHistories } from '@queries/productBulk/useUploadHistories';
import { timestampToDate } from '@utils/time';
import { deleteUploadHistory, uploadFile } from '@apis/productBulk/upload';
import { useUploadHistoriesCount } from '@queries/productBulk/useUploadHistoriesCount';
import { downloadFile } from '@utils/file';
import { PRODUCT_BULK_UPLOAD_TEMPLATE } from '@constants/templates';

const PAGE_SIZE = 50;
export const ProductBulkUpload = () => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const { data: historiesData, refetch } = useUploadHistories({
    page,
  });

  const { data: totalCount, refetch: refetchCount } = useUploadHistoriesCount();

  const handleConfirmFile = async (file: File) => {
    // error
    const { data, message } = await uploadFile(file);

    if (message !== 'OK') {
      alert(message);
    } else {
      refetch();
      refetchCount();
      const uploadId = Number(data[0]);
      navigate(`/products/upload/${uploadId}`);
    }
  };

  const handleOnViewTemplate = () => {
    downloadFile(PRODUCT_BULK_UPLOAD_TEMPLATE, 'product_bulk_upload_template');
  };

  const handleDeleteUpload = async (uploadId: number) => {
    if (!!window.confirm('Are you sure to delete this history?')) {
      await deleteUploadHistory(uploadId);
    }

    refetch();
    refetchCount();
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        Bulk Product Upload
      </Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button
          text={'TEMPLATE'}
          color="secondary"
          onClick={handleOnViewTemplate}
        />
      </Box>
      <Box width={'100%'} mt={2}>
        <DragDropFile onConfirmFile={handleConfirmFile} />
      </Box>

      <Toolbar />
      <Typography variant="h5">History</Typography>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 2,
        }}
      >
        <Table>
          <TableHead
            sx={{
              backgroundColor: '#f5f5f5',
            }}
          >
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Upload Type</StyledTableCell>
              <StyledTableCell>Uploaded Qty.</StyledTableCell>
              <StyledTableCell>File name</StyledTableCell>
              <StyledTableCell>Date & Time</StyledTableCell>
              <StyledTableCell>Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(historiesData?.data || []).map((history, index) => (
              <StyledTableRow
                key={history.productBulkUploadId}
                onClick={() => {
                  navigate(`/products/upload/${history.productBulkUploadId}`);
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{history.uploadType}</StyledTableCell>
                <StyledTableCell>{history.okayCount}</StyledTableCell>
                <StyledTableCell>{history.filename}</StyledTableCell>
                <StyledTableCell>
                  {timestampToDate(history.createdAt)}
                </StyledTableCell>
                <StyledTableCell>
                  <IconButton
                    onClick={() => {
                      handleDeleteUpload(history.productBulkUploadId);
                    }}
                  >
                    <DeleteOutline />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#F3F6F9',
    cursor: 'pointer',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
