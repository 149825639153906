import API from '../API';
import { PageParams, PageResponse } from '@models/api';
import { ProductStatus, Status, UploadStatus } from '@models/product';
import { ProductSaleType } from '../product';

export const uploadFile = async (file: File) => {
  const formData = new FormData();

  formData.append('file', file, file.name);

  const { data } = await API.post<PageResponse<any>>(
    '/v1/product-bulk/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return data;
};

export const getUploadHistoriesCount = async () => {
  const { data } = await API.get<PageResponse<number>>(
    '/v1/product-bulk/upload/count'
  );
  return data.data[0];
};

export type GetUploadHistoriesResponse = {
  productBulkUploadId: number;
  uploadType: 'CREATE' | 'UPDATE';
  createdAt: number;
  filename: string;
  isDeleted: boolean;
  updatedAt: number;
  okayCount: number;
  failedCount: number;
  totalCount: number;
  status: Status;
};

export const getUploadHistories = async (params: PageParams) => {
  const { data } = await API.get<PageResponse<GetUploadHistoriesResponse>>(
    '/v1/product-bulk/upload',
    { params }
  );
  return data;
};

export const getUploadHistory = async (uploadId: number) => {
  const { data } = await API.get<PageResponse<GetUploadHistoriesResponse>>(
    `/v1/product-bulk/upload/${uploadId}`
  );
  return data;
};

export const deleteUploadHistory = async (uploadId: number) => {
  const { data } = await API.delete(`/v1/product-bulk/upload/${uploadId}`);
  return data;
};

export const getUploadHistoryDetailsCount = async (uploadId: number) => {
  const { data } = await API.get<PageResponse<number>>(
    `/v1/product-bulk/upload/${uploadId}/details/count`
  );
  return data.data[0];
};

export type GetUploadHistoryDetailsResponse = {
  productBulkUploadDetailId: number;
  productBulkUploadId: number;
  uploadStatus: UploadStatus;
  productCode?: string;
  productType: string;
  productSaleType: ProductSaleType;
  productName: string;
  description: string;
  specification: string;
  requiredCustomerData: string;
  productStatus: ProductStatus;
  stockId: number;
  price: number;
  supplierTaskData: string;
  countryCodes: string;
  createdAt: number;
  updatedAt: number;
};

export type GetUploadHistoryDetailsParams = {
  onlyFailed?: boolean;
};

export const getUploadHistoryDetails = async (
  uploadId: number,
  params: PageParams & GetUploadHistoryDetailsParams
) => {
  const { data } = await API.get<PageResponse<GetUploadHistoryDetailsResponse>>(
    `/v1/product-bulk/upload/${uploadId}/details`,
    { params }
  );
  return data;
};

export const confirmUpload = async (uploadId: number) => {
  const { data } = await API.post(
    `/v1/product-bulk/upload/${uploadId}/confirm`
  );
  return data;
};
