import API from './API';
import { PageParams, PageResponse } from '@models/api';
import { EsimStatus, EsimType } from '@models/stock';
import { OrderEventType, OrderStatus } from '@models/order';
import { ProductSaleType } from './product';

export type GetOrdersParams = {
  orderCode?: string;
  productCode?: string;
  productSaleType?: ProductSaleType;
  productName?: string;
  stockId?: number;
  partnerId?: number;
  orderStatus?: OrderStatus;
  createdAtFrom?: number;
  createdAtTo?: number;
  reservedAtFrom?: number;
  reservedAtTo?: number;
  requestOrderId?: string;
  externalOrderId?: string;
};

export type OrderParameterType =
  | 'REDEMPTION_CODE'
  | 'LPA_URL'
  | 'ICCID'
  | 'MSISDN'
  | 'IMEI'
  | 'EID'
  | 'ACTIVATION_DATE';

export type GetOrderEsimsParams = {
  esimType: EsimType;
} & (
  | {
      parameterType: OrderParameterType;
      parameterValue: string;
    }
  | never
);

export type OrderResponse = {
  orderId: number;
  orderCode: String;
  externalReserveCode?: string;
  externalOrderId?: string;
  paymentAmount: number;
  refundAmount: number;
  orderStatus: OrderStatus;
  quantity: number;
  refundQuantity: number;
  product: {
    productId: number;
    productCode: string;
    productName: string;
    productSaleType: ProductSaleType;
  };
  stock: {
    stockId: number;
    stockCode: string;
    esimType: EsimType;
  };
  partner: {
    partnerId: number;
    name: string;
  };
  reservedAt?: number;
  memo?: string;
  createdAt: number;
  updatedAt: number;
};

export type OrderReservationItem = {
  orderReservationItemId: number;
  orderId: number;
  itemStatus: OrderReservationItemStatus;
  email: string;
  airportCode: string;
  customerName: string;
  passportData?: PassportData;
  createdAt: number;
  updatedAt: number;
};
export enum OrderReservationItemStatus {
  RESERVED = 'RESERVED',
  CANCELED = 'CANCELED',
}

export type OrderEsimResponse = {
  esimId: number;
  redemptionCode?: string;
  lpaUrl?: string;
  iccid?: string;
  msisdn?: string;
  pin?: string;
  puk?: string;
  serialNo?: string;
  esimType: EsimType;
  expireAt?: number;
  activationDate?: number;
  validDays?: number;
  imei?: string;
  eid?: string;
  customerName?: string;
  esimStatus: EsimStatus;
  customerDetail?: {
    passportData?: PassportData;
  };
};

export type RefundType = 'PARTIAL_REFUND' | 'FULL_REFUND';
export type OrderRefundRequest = {
  esimType: EsimType;
  refundType: RefundType;
  esimIds: number[];
  refundFee: number;
};
export type OrderRefundResponse = {
  refundedEsimIds: number[];
};

export type OrderEventResponse = {
  orderEventId: number;
  eventType: OrderEventType;
  owner: string;
  description: string;
  createdAt: number;
};

export const getOrdersCount = async (params: GetOrdersParams) => {
  const { data } = await API.get<PageResponse<number>>('/v1/orders/count', {
    params,
  });
  return data.data[0];
};

export const getOrders = async (
  params: GetOrdersParams & PageParams
): Promise<PageResponse<OrderResponse>> =>
  (await API.get<PageResponse<OrderResponse>>('/v1/orders', { params })).data;

export const getOrder = async (
  orderId: number
): Promise<PageResponse<OrderResponse>> =>
  (await API.get<PageResponse<OrderResponse>>(`/v1/orders/${orderId}`)).data;

export const getOrderReservationItems = async (
  orderId: number
): Promise<PageResponse<OrderReservationItem>> =>
  (
    await API.get<PageResponse<OrderReservationItem>>(
      `/v1/orders/${orderId}/reservation-items`
    )
  ).data;

export const getOrderEsimsCount = async (
  orderId: number,
  params: GetOrderEsimsParams
) => {
  const { data } = await API.get<PageResponse<number>>(
    `/v1/orders/${orderId}/esims/count`,
    { params }
  );

  return data.data[0];
};

export const getOrderEsims = async (
  orderId: number,
  params: GetOrderEsimsParams & PageParams
): Promise<PageResponse<OrderEsimResponse>> =>
  (
    await API.get<PageResponse<OrderEsimResponse>>(
      `/v1/orders/${orderId}/esims`,
      {
        params,
      }
    )
  ).data;

export const refundOrder = async (
  orderId: number,
  request: OrderRefundRequest
): Promise<PageResponse<OrderRefundRequest>> =>
  (
    await API.post<PageResponse<OrderRefundRequest>>(
      `/v1/orders/${orderId}/refund`,
      request
    )
  ).data;

export const getOrderEvents = async (
  orderId: number
): Promise<PageResponse<OrderEventResponse>> =>
  (
    await API.get<PageResponse<OrderEventResponse>>(
      `/v1/orders/${orderId}/events`
    )
  ).data;

export const addOrderMemo = async (
  orderId: number,
  memo: string
): Promise<void> =>
  API.post(`/v1/orders/${orderId}/memo`, {
    memo: memo,
  });

export const deleteOrderMemo = async (orderId: number, orderEventId: number) =>
  API.delete(`/v1/orders/${orderId}/memo/${orderEventId}`);

export const exportOrderDetailEsimsExcel = async (
  orderId: number,
  params: GetOrderEsimsParams
) => {
  const { data } = await API.get(`/v1/orders/${orderId}/esims/export-excel`, {
    params,
    responseType: 'blob',
  });
  return data;
};

export const exportOrders = async (params: GetOrdersParams) => {
  const { data } = await API.get(`/v1/orders/export-excel`, {
    params,
    responseType: 'blob',
  });
  return data;
};

export const exportOrderEsims = async (params: GetOrdersParams) => {
  const { data } = await API.get(`/v1/orders/esims/export-excel`, {
    params,
    responseType: 'blob',
  });
  return data;
};

export const exportOrderReservationItemsExcel = async (orderId: number) => {
  const { data } = await API.get(
    `/v1/orders/${orderId}/reservation-items/export-excel`,
    {
      responseType: 'blob',
    }
  );
  return data;
};

export type Locale = 'ko' | 'ja' | 'en' | 'zh-CN' | 'zh-TW';
export type CreateOrderRequest = {
  productCode: string;
  quantity: number;
  customerDetails: CustomerDetail[];
  sendEmail: boolean;
};
export type CustomerDetail = {
  activationDate?: string;
  validDays?: number;
  imei?: string;
  eid?: string;
  customerName?: string;
  passportData?: PassportData;
  email?: string;
  phoneNumber?: string;
};
export type PassportData = {
  passportNumber?: string;
  passportName?: string;
  passportBirthdate?: string;
  passportCountry?: string;
  passportGender?: string;
};

export type CreateOrderResponse = {
  orderId: string;
  orderCode: string;
};

export const createOrder = async (request: CreateOrderRequest) =>
  (
    await API.post<PageResponse<CreateOrderResponse>>(
      '/v1/orders/manual',
      request
    )
  ).data;
