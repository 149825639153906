import API from './API';
import { PageParams, PageResponse } from '@models/api';
import {
  ProductCoverageAggregate,
  ProductRequiredData,
  ProductSpecification,
  ProductStatus,
} from '@models/product';

export enum ProductSaleType {
  REAL_TIME = 'REAL_TIME',
  RESERVATION = 'RESERVATION',
}

export enum ProductType {
  ESIM = 'ESIM',
  RESERVE_ESIM = 'RESERVE_ESIM',
  ROUTER = 'ROUTER',
}

export type GetProductsParams = {
  productSaleType?: ProductSaleType;
  productCode?: string;
  productName?: string;
  stockId?: number;
  countryId?: number;
  productStatus?: ProductStatus;
};

export type ProductResponse = {
  productId: number;
  productCode: string;
  productSaleType: ProductSaleType;
  productType: ProductType;
  productName: string;
  description: string;
  price: number;
  productCoverages: ProductCoverageAggregate[];
  specification: ProductSpecification;
  requiredData: ProductRequiredData;
  productStatus: ProductStatus;
  stock: {
    stockId: number;
    stockCode: string;
  };
  apiParameter?: Record<string, string>;
  totalShippedCount?: number;
  lastWeekShippedCount?: number;
  refundedCount?: number;
};

export type ProductCoverageUpsertRequest = {
  countryId: number;
};

export type ProductUpsertRequest = {
  productCode: string;
  productSaleType: ProductSaleType;
  productType: ProductType;
  productName: string;
  description: string;
  specification: ProductSpecification;
  requiredData: ProductRequiredData;
  productStatus: ProductStatus;
  stockId: number;
  price: number;
  apiParameter?: string;
  productCoverages: ProductCoverageUpsertRequest[];
  isDeleted?: boolean;
};

export const getProductsCount = async (params: GetProductsParams) => {
  const { data } = await API.get<PageResponse<number>>('/v1/products/count', {
    params,
  });
  return data.data[0];
};

export const getProducts = async (
  params: GetProductsParams & PageParams
): Promise<PageResponse<ProductResponse>> =>
  (await API.get<PageResponse<ProductResponse>>('/v1/products', { params }))
    .data;

export const exportProductsExcel = async (params: GetProductsParams) => {
  const { data } = await API.get(`/v1/products/export-excel`, {
    params,
    responseType: 'blob',
  });
  return data;
};

export const getProduct = async (
  productId: number
): Promise<PageResponse<ProductResponse>> =>
  (await API.get<PageResponse<ProductResponse>>(`/v1/products/${productId}`))
    .data;

export const createProduct = async (
  request: ProductUpsertRequest
): Promise<PageResponse<ProductResponse>> =>
  (await API.post<PageResponse<ProductResponse>>(`/v1/products`, request)).data;

export const editProduct = async (
  productId: number,
  request: ProductUpsertRequest
): Promise<ProductResponse> =>
  (await API.put<ProductResponse>(`/v1/products/${productId}`, request)).data;
