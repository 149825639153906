export type Menu = {
  menu: string;
  subMenu: {
    name: string;
    path: string;
  }[];
};
export const ADMIN_MENU_LIST: Menu[] = [
  {
    menu: 'Home',
    subMenu: [
      {
        name: 'Home',
        path: '/home',
      },
    ],
  },
  {
    menu: 'Partner',
    subMenu: [
      {
        name: 'Partners',
        path: '/partners',
      },
      {
        name: 'Add Partner',
        path: '/partners/add',
      },
    ],
  },
  {
    menu: 'User',
    subMenu: [
      {
        name: 'Users',
        path: '/users',
      },
    ],
  },
  {
    menu: 'Product',
    subMenu: [
      {
        name: 'Products',
        path: '/products',
      },
      {
        name: 'Add Product',
        path: '/products/add',
      },
      {
        name: 'Bulk Upload',
        path: '/products/upload',
      },
    ],
  },
  {
    menu: 'Stock',
    subMenu: [
      {
        name: 'Stock Codes',
        path: '/stocks/codes',
      },
      {
        name: 'eSIM Inventory',
        path: '/stocks/esim-inventory',
      },
      {
        name: 'Bulk Upload',
        path: '/stocks/upload',
      },
      {
        name: 'Bulk Update',
        path: '/stocks/update',
      },
      {
        name: 'Suppliers',
        path: '/stocks/suppliers',
      },
    ],
  },
  {
    menu: 'Order',
    subMenu: [
      {
        name: 'Orders',
        path: '/orders',
      },
    ],
  },
];

export const PARTNER_MENU_LIST = [
  {
    menu: 'Partner',
    subMenu: [
      {
        name: 'Partner',
        path: '/my-detail',
      },
    ],
  },
  {
    menu: 'Order',
    subMenu: [
      {
        name: 'Orders',
        path: '/orders',
      },
    ],
  },
];

export const PARTNER_MENU_LIST_WITH_MANUAL_ORDER = [
  {
    menu: 'Partner',
    subMenu: [
      {
        name: 'Partner',
        path: '/my-detail',
      },
    ],
  },
  {
    menu: 'Order',
    subMenu: [
      {
        name: 'Orders',
        path: '/orders',
      },
      {
        name: 'Manual Order',
        path: '/orders/add',
      },
    ],
  },
];
