import Button from '@components/common/Button';
import TextInput from '@components/common/TextInput';
import {
  Box,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import CheckBox from '@components/common/CheckBox';
import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateDetail } from '@queries/esimBulk/useUpdateDetail';
import {
  confirmUpdate,
  GetUpdateHistoriesResponse,
} from '@apis/esimBulk/update';
import useAsyncEffect from '@hooks/useAsyncEffect';
import { useUpdateDetails } from '@queries/esimBulk/useUpdateDetails';
import { useUpdateDetailsCount } from '@queries/esimBulk/useUpdateDetailsCount';
import { Status, UpdateStatus } from '@models/esim';
import { timestampToDate } from '@utils/time';

const PAGE_SIZE = 50;

export const EsimBulkUpdateDetail = () => {
  const { updateId } = useParams();
  const navigate = useNavigate();
  const [onlyFailed, setOnlyFailed] = useState(false);

  const [data, setData] = useState<GetUpdateHistoriesResponse>({
    esimInventoryBulkUpdateId: 0,
    createdAt: 0,
    filename: '',
    isDeleted: false,
    stock: {
      stockCode: '',
      stockName: '',
    },
    updatedAt: 0,
    okayCount: 0,
    failedCount: 0,
    totalCount: 0,
    status: Status.PENDING,
  });

  const [page, setPage] = useState(1);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const { data: updateDetail, isLoading } = useUpdateDetail(Number(updateId));

  const { data: uploadDetailsData } = useUpdateDetails({
    updateId: Number(updateId),
    page: page,
    pageSize: PAGE_SIZE,
    params: {
      onlyFailed,
    },
  });

  const { data: totalCount } = useUpdateDetailsCount(Number(updateId));

  const handleToggleOnlyFailure = () => {
    setOnlyFailed((prev) => !prev);
  };

  const handleOnCancel = () => {
    return navigate(-1);
  };

  const handleOnUpdate = async () => {
    const { message } = await confirmUpdate(Number(updateId));

    if (message !== 'OK') {
      alert(message);
    } else {
      navigate(`/stocks/update`);
    }
  };

  useAsyncEffect(async () => {
    if (!updateId || !updateDetail) return;
    setData(updateDetail?.data[0]);
  }, [updateDetail, updateId]);

  if (isLoading) return null;
  return (
    <>
      <Typography variant="h4" component="h4">
        Bulk eSIM Update
      </Typography>
      <Box
        mt={2}
        mb={3}
        gap={2}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'flex-end'}
          gap={2}
          flexDirection={'row'}
        >
          <TextInput
            label={'File'}
            value={data.filename}
            disabled
            inputWidth={500}
          />
          <TextInput
            label={'Total'}
            disabled
            value={data.totalCount.toLocaleString()}
            inputWidth={200}
          />
          <TextInput
            label={'OK'}
            disabled
            value={data.okayCount.toLocaleString()}
            inputWidth={200}
          />
          <TextInput
            label={'Fail'}
            disabled
            value={data.failedCount.toLocaleString()}
            inputWidth={200}
          />
        </Box>
      </Box>

      {/* List Section */}
      <CheckBox
        label={'Only Failure'}
        value={onlyFailed}
        onCheck={handleToggleOnlyFailure}
      />
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 1,
        }}
      >
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Index</StyledTableCell>
              <StyledTableCell>Stock Code</StyledTableCell>
              <StyledTableCell>LPA URI</StyledTableCell>
              <StyledTableCell>Redeem</StyledTableCell>
              <StyledTableCell>eSIM State</StyledTableCell>
              <StyledTableCell>Exp. Date</StyledTableCell>
              <StyledTableCell>Result</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(uploadDetailsData?.data || []).map((row, index) => (
              <StyledTableRow
                key={row.esimInventoryBulkUpdateDetailId}
                isError={row.updateStatus !== UpdateStatus.OK}
              >
                <StyledTableCell component="th" scope="row">
                  {index + 1}
                </StyledTableCell>
                <StyledTableCell>{data.stock.stockCode}</StyledTableCell>
                <StyledTableCell>{row.lpaUrl}</StyledTableCell>
                <StyledTableCell>{row.redemptionCode}</StyledTableCell>
                <StyledTableCell>
                  {(row.esimStatusFrom ? `${row.esimStatusFrom} -> ` : '') +
                    row.esimStatusTo}
                </StyledTableCell>
                <StyledTableCell>
                  {!!row.expireAtFrom
                    ? `${timestampToDate(row.expireAtFrom, 'YYYY-MM-DD')} -> `
                    : ''}
                  {timestampToDate(row.expireAtTo, 'YYYY-MM-DD')}
                </StyledTableCell>
                <StyledTableCell>{row.updateStatus}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Pagination
          count={Math.ceil((totalCount ?? 0) / PAGE_SIZE)}
          page={page}
          onChange={handleChangePage}
        />
      </Box>
      {data.status === Status.PENDING && (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          gap={2}
        >
          <Button text={'CANCEL'} onClick={handleOnCancel} color={'error'} />
          <Button text={'UPDATE'} onClick={handleOnUpdate} />
        </Box>
      )}
    </>
  );
};

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: '#fff',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
}));

const StyledTableRow = styled(TableRow)(
  ({ isError }: { isError: boolean }) => ({
    backgroundColor: isError ? '#FDE7E9' : '#fff',
    '&:hover': {
      backgroundColor: isError ? '#FDE7E9' : '#F5F5F5',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  })
);
