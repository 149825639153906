import { Box, Typography } from '@mui/material';
import Button from '@components/common/Button';
import DragDropFile from '@components/common/DragDropFile';
import { useNavigate } from 'react-router-dom';
import { downloadFile } from '@utils/file';
import { ESIM_BULK_UPDATE_TEMPLATE } from '@constants/templates';
import { updateFile } from '@apis/esimBulk/update';

export const EsimBulkUpdate = () => {
  const navigate = useNavigate();

  const handleOnViewTemplate = () => {
    downloadFile(ESIM_BULK_UPDATE_TEMPLATE, 'esim_bulk_update_template');
  };

  const handleConfirmFile = async (file: File) => {
    const { data, message } = await updateFile(file);

    if (message !== 'OK') {
      alert(message);
    } else {
      const updateId = Number(data[0]);
      navigate(`/stocks/update/${updateId}`);
    }
  };

  return (
    <>
      <Typography variant="h4" component="h4">
        Bulk eSIM Information Update
      </Typography>
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button
          text={'TEMPLATE'}
          color="secondary"
          onClick={handleOnViewTemplate}
        />
      </Box>
      <Box width={'100%'} mt={2} justifyContent={'center'}>
        <DragDropFile onConfirmFile={handleConfirmFile} />
      </Box>
    </>
  );
};
