export const S3_BUCKET = 'https://admin.esimsta.com/resources/';
export const ESIM_BULK_UPLOAD_TEMPLATE =
  S3_BUCKET + 'template_esim_upload_v1.xlsx';
export const ESIM_BULK_UPDATE_TEMPLATE =
  S3_BUCKET + 'template_esim_update_v1.xlsx';

export const PARTNER_PRICE_UPLOAD_TEMPLATE =
  S3_BUCKET + 'template_partner_price_upload_v1.xlsx';

export const PRODUCT_BULK_UPLOAD_TEMPLATE =
  S3_BUCKET + 'template_product_upload_v1.xlsx';
