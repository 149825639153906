import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getUploadHistoriesCount } from '@apis/productBulk/upload';

export const useUploadHistoriesCount = () => {
  const queryOptions: UseQueryOptions<number, Error> = {
    queryKey: ['productUploadHistoriesCount'],
    queryFn: () => {
      return getUploadHistoriesCount();
    },
  };
  return useQuery(queryOptions);
};
